import React, { useState, useEffect } from "react";


function WorkTabs() {

   



    return (
        <div>
            
            {/* image modal */}

        </div>
    );
}

export default WorkTabs;